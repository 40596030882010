.cell {
  background-color: #f5f9ff;
  color: #000000;
  /* margin: 14px; */
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
  font-family: "Garamond", serif;
  font-size: 16pt;
  border-radius: 12px;
}

h1 {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
}


.headerMobile {
  padding: 17px;
  padding-bottom: 22px;
  text-align: center;
  /* line-height: 50px; */
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 3%;
  margin-bottom: 2%;
  /* color: #225896; */
  
}

.homeHeading {

}

.mainMobile {
  padding: 16px;
  padding-top: 12px;
  /* height: 500px; */
  margin-left: 3%;
  margin-right: 3%;
  /* margin-top: 3%; */
  margin-bottom: 2%;
  /* text-align: justify;
  text-justify: inter-word; */
}

.headerDesktop {
  padding: 16px;
  padding-bottom: 26px;
  text-align: center;
  /* line-height: 60px; */
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
  margin-bottom: 1%;
}


.logoTop {
  width: 100px;
  opacity: 50%;
  position: relative;
  left: 0px;
  top: 0px;
 }

.mainDesktop {
  padding: 20px;
  /* height: 500px; */
  margin-left: 10%;
  margin-right: 10%;
}

.headshotMobile {
  min-width: 150px;
  width: 40%;
  border-radius: 12px;
  float: right;
  margin-right: 0px;
  margin-left: 16px;
  margin-bottom: 28px;
  margin-top: 4px;
}

.headshotDesktop {
  min-width: 250px;
  width: 30%;
  border-radius: 12px;
  float: right;
  margin-right: 4px;
  margin-left: 16px;
  margin-bottom: 6px;
  margin-top: 4px;
}

a {
  color:#225896;
  text-decoration: underline;
  font-weight: 500;
}

.subHeadingMobile {
  font-size: 20pt;
  text-align: center;
  margin-bottom: 12px;
  text-decoration: underline;
  margin-top: 30px;
  margin-bottom: 16px;
  clear: both;
}

.subHeadingDesktop {
  font-size: 24pt;
  text-align: center;
  margin-bottom: 12px;
  text-decoration: underline;
  margin-top: 30px;
  margin-bottom: 20px;
  /* clear: both; */
}

strong {
  font-weight: 600;
}

h2 {
  margin: 4px
}

/* .gifMobile {
  min-width: 155px;
  width: 50%;
  border-radius: 12px;
  float: right;
  margin-right: 0px;
  margin-left: 16px;
  margin-bottom: 16px;
  margin-top: 4px;
} */

.gifMobile {
  /* min-width: 155px; */
  width: 100%;
  border-radius: 12px;
  /* float: right; */
  margin: auto;
  display: block;
  margin-bottom: 18px;
  margin-top: 4px;
}

.gifDesktop {
  min-width: 400px;
  width: 50%;
  border-radius: 12px;
  float: right;
  margin-right: 4px;
  margin-left: 16px;
  margin-bottom: 26px;
  margin-top: 4px;
}

.subHeadingDesktopScratch {
  font-size: 24pt;
  text-align: center;
  margin-bottom: 12px;
  text-decoration: underline;
  margin-top: 30px;
  margin-bottom: 20px;
  clear: both;
}

.subHeadingMobileScratch {
  font-size: 20pt;
  text-align: center;
  margin-bottom: 12px;
  text-decoration: underline;
  margin-top: 30px;
  margin-bottom: 16px;
  clear: both;
}

ion-router-link {
  color:#225896;
  text-decoration: underline;
  font-weight: 500;
}